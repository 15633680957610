import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "../css/main.css";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div className="page-content">
      {/*
        Set Default <head> values for the site.
        These values are overriden by child instances
        of <Helmet> inside the  TemplateWrapper.
      */}
      <Helmet>
        <html lang="en" className="has-navbar-fixed-top" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="/img/og-image.png" />

        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Amos Institute",
            "url": "https://amosinstitute.com",
            "logo": "/img/meta-logo.png",
            "sameAs": [
              "https://www.instagram.com/amosinstitute",
              "https://www.facebook.com/theamosinstitute",
              "https://twitter.com/amosinstitute"
            ]
          }
          `}
        </script>

        <meta
          name="google-site-verification"
          content="u0qupTgKgjMXcvDkgYwxnjQAP7yIjsoUfprHcdvcHOs"
        />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
