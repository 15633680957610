import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import { GatsbyImage, getSrc } from "gatsby-plugin-image";

const BlogPostTemplate = ({
  content,
  contentComponent,
  featuredimage,
  featuredimageAltTxt,
  author,
  tags,
  title,
  helmet,
  video,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <React.Fragment>
      {tags.map((tag) => (
        <div
          className="blog-post-header-bg"
          key={tag + `-bg`}
          data-tag={tag}
        ></div>
      ))}
      <section className="section blog-post">
        {helmet || ""}
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h1 className="is-size-2">{title}</h1>
              <small className="has-text-grey">by {author}</small>
              {tags.map((tag) => (
                <small key={tag + `tag`}>
                  <Link
                    className="category-tag"
                    data-tag={tag}
                    to={`/blog/${kebabCase(tag)}/`}
                  >
                    {tag}
                  </Link>
                </small>
              ))}
              {video !== null ? (
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    paddingTop: "30px",
                    marginTop: "30px",
                    marginBottom: "35px",
                    height: "0",
                    overflow: "hidden",
                  }}
                >
                  <iframe
                    title={"Amos Institute Media"}
                    width="640"
                    height="360"
                    src={video}
                    frameBorder="0"
                    allowFullScreen
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      bottom: "0",
                      right: "0",
                      width: "100%",
                      height: "100%",
                      overflow: "100%",
                    }}
                  ></iframe>
                </div>
              ) : (
                <GatsbyImage
                  image={featuredimage.childImageSharp.gatsbyImageData}
                  alt={featuredimageAltTxt}
                  className={"blog-img"}
                />
              )}

              <PostContent content={content} />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Amos Institute Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta property="og:title" content={`${post.frontmatter.title}`} />
            <meta property="og:type" content="article" />
            <meta property="og:site_name" content="Amos Institute" />
            <meta name="author" content={`${post.frontmatter.author}`}></meta>
            <meta
              property="og:description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              property="og:image"
              content={getSrc(
                post.frontmatter.featuredimage.childImageSharp.gatsbyImageData
              )}
            />

            <script type="application/ld+json">
              {`
                {
                  "@context": "http://schema.org",
                  "@type": "BlogPosting",
                  "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": "${location.href}"
                  },
                  "headline": "${post.frontmatter.title}",
                  "image": [
                    "${post.frontmatter.featuredimage.childImageSharp.gatsbyImageData}"
                  ],
                  "datePublished": "${post.frontmatter.date}",
                  "dateModified": "${post.frontmatter.date}",
                  "author": {
                    "@type": "Person",
                    "name": "${post.frontmatter.author}"
                  },
                   "publisher": {
                    "@type": "Organization",
                    "name": "Amos Institute",
                    "logo": {
                      "@type": "ImageObject",
                      "url": "https://amosinstitute.com/img/meta-logo.png"
                    }
                  },
                  "description": "${post.frontmatter.description}"
                }
              `}
            </script>
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        author={post.frontmatter.author}
        featuredimage={post.frontmatter.featuredimage}
        featuredimageAltTxt={post.frontmatter.featuredimageAltTxt}
        video={post.frontmatter.video}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MM-DD-YYYY")
        title
        description
        featuredimageAltTxt
        author
        video
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              width: 900
            )
          }
        }
        tags
      }
    }
  }
`;
